<template>
    <div>
        <v-container fluid class="pt-0 mt-5">
            <v-row no-gutters>
                <v-col cols="12">
                    <!-- 绿色框 -->
                    <v-alert :dismissible="false" class="orange white--text" dark border="left" icon="fas fa-bell">
                        <span>Notifications</span>
                    </v-alert>
                </v-col>
				<v-col cols="12" class="text-right mb-8">
					<!-- 添加新的通知 -->
					<v-btn tile small color="orange white--text" @click="showNewNotificationDialog">New Notification</v-btn>
				</v-col>
				<v-col cols="12">
					<v-row dense v-if="loading"> 
                        <v-col cols="12">
                            <v-skeleton-loader dense type="table-row-divider@20" class="mx-auto"></v-skeleton-loader>
                        </v-col>
                    </v-row>
					<!-- 表头 -->
					<v-data-table dense :headers="notificationsHeaders" :items="notifications" hide-default-footer v-if="!loading">
						<!-- 表头上侧 -->
						<!-- <template v-slot:top>
							<v-toolbar flat color="white">
								<v-spacer></v-spacer>
								
								<div class="text-center">
									<v-btn tile small color="orange white--text" @click="showNewNotificationDialog">New Notification</v-btn>
								</div>
							</v-toolbar>
							<v-divider />
						</template> -->
						<!-- 表数据 -->
						<template v-slot:body="{ notification }">
							<tbody>
								<tr v-for="(notification, index) in notifications" :key="notification.id">
									<td>{{(indexNo - 1)* pageSize +(index+1)}}</td>
									<td>{{ notification.userId }}</td>
									<td>{{ notification.message }}</td>
									<td>{{ notification.autoIndex }}</td>
									<td>{{ common.formatTime(notification.timestamp) }}</td>
									<td>
										<v-btn icon @click="showEditNotificationDialog(notification)">
												<v-icon small>fas fa-edit</v-icon>
										</v-btn>
										<v-btn icon class="ml-2" @click="showDeleteDialog(notification.id)">
												<v-icon small>fas fa-trash-alt</v-icon>
										</v-btn>
									</td>
								</tr>
							</tbody>
						</template>
					</v-data-table>
					<v-divider v-if="!loading"></v-divider>
					<!-- 底部分页 -->
					<template>
					<div class="text-xs-center mt-5" v-if="totalElements>0">
						<v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" circle v-model="pageNo" color="orange" :length="totalPage" @input="getNotifications" :total-visible="15"></v-pagination>
					</div>
					</template>
				</v-col>
            </v-row>
        </v-container>
		<v-dialog v-model="notificationDialog" persistent max-width="500px">
		    <!-- 模态框 -->
		    <v-card>
		        <v-card-title>
		            <span class="fs-24">{{ notificationDialogTitle }}</span>
		            <v-spacer></v-spacer>
		            <v-btn @click="notificationDialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
		        </v-card-title>
		        <v-card-text>
		            <v-container>
		                <v-form ref="notificationForm" v-model="notificationFormValid">
		                    <v-row>
		                        <!-- 触发 -->
		                        <v-col cols="12">
									<v-textarea outlined label="Message" v-model="notificationForm.message" :rules="[v => !!v || 'This field is required']" required></v-textarea>
		                        </v-col>
		                    </v-row>
		                </v-form>
		            </v-container>
		        </v-card-text>
		        <v-card-actions>
		            <v-spacer></v-spacer>
		            <v-btn v-if="notificationDialogTitle == 'New Notification'" class="orange--text" text @click="addSystemNotification" :disabled="!notificationFormValid" :loading="notificationLoading">Save</v-btn>
								<v-btn v-if="notificationDialogTitle == 'Edit Notification'" class="orange--text" text @click="editNotification" :disabled="!notificationFormValid" :loading="notificationLoading">Update</v-btn>
		        </v-card-actions>
		    </v-card>
		</v-dialog>
		<v-dialog v-model="deleteDialog" persistent max-width="500px">
		    <!-- 模态框 -->
		    <v-card>
		        <v-card-title>
		            <span class="fs-24">Delete Notification</span>
		            <v-spacer></v-spacer>
		            <v-btn @click="deleteDialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
		        </v-card-title>
		        <v-card-text class="mt-3">
		            <div class="fs-20">Do you want to delete this notification?</div>
		        </v-card-text>
		        <v-card-actions>
		            <v-spacer></v-spacer>
					<v-btn text tile color="orange white--text" @click="deleteDialog = false">Cancel</v-btn>
					<v-btn class="red--text" text @click="deleteNotification">Delete</v-btn>
		        </v-card-actions>
		    </v-card>
		</v-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '../../config';
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                notificationsHeaders: [{
                        text: 'No',
                        value: 'no',
                        sortable: false,
                    },
                    {
                        text: 'User',
                        value: 'userId',
                        sortable: false,
                    },
                    {
                        text: 'Message',
                        value: 'message',
                        sortable: false,
						width: 720
                    },
					{
					    text: 'Index',
					    value: 'autoIndex',
					    sortable: false,
					},
					{
					    text: 'Time',
					    value: 'timestamp',
					    sortable: false,
					},
					{
					    text: 'Action',
					    value: 'action',
							sortable: false
					}
                ],
                notifications: [],
                totalElements: 0,
                totalPage: 0,
                pageNo: 1,
                pageSize: 15,
                menu:false,
				indexNo:0,
				loading:false,
				notificationDialog: false,
				notificationDialogTitle: '',
				deleteDialog: false,
				deleteNotificationId: null,
				notificationFormValid: true,
				notificationLoading: false,
				notificationForm: {
					message: null
				}
            }
        },
        computed: {
            ...mapGetters(['user']),
        },
        watch: {
            
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({ name: 'login' })
            };
			this.getNotifications(1);
        },
        created() {
           
        },
        methods: {
		   // 获取通知数据
		   getNotifications(pageNo) {
			   this.loading = true;
		       let api = config.baseUrl + '/notification/private/getNotifications?pageNo=' + (pageNo - 1) + '&pageSize=' + this.pageSize;
		       this.$http.get(api).then(
		           response => {
		               if (response.data.code == 200) {
						   this.loading = false;
		                   this.notifications = response.data.result.notifications;
		                   this.totalElements = response.data.result.totalElements;
		                   this.totalPage = Math.ceil(this.totalElements / this.pageSize);
		                   this.indexNo = pageNo;
		               }
		           },
		       )
		   },
		   // 添加系统通知
		   addSystemNotification(){
			   if (this.$refs.notificationForm.validate()) {
			       let api = config.baseUrl + '/notification/admin/private/addSystemNotification';
				   this.notificationLoading = true;
			       this.$http.post(api, this.notificationForm).then(response => {
			           let data = response.data;
			           if (data.code == 200) {
			               this.$store.dispatch('snackbarMessageHandler', "添加成功");
						   this.notificationDialog = false;
			               this.getNotifications(1);
						   this.$refs.notificationForm.reset();
						   this.notificationLoading = false;
			           }
			       }, error => {
			           this.$store.dispatch('snackbarMessageHandler', "error");
			           this.notificationLoading = false;
			       });
			   }
		   },
		   // 显示新增通知弹窗
		   showNewNotificationDialog(){
				this.notificationDialogTitle = 'New Notification';
				this.notificationForm = {
					message: null
				};
				this.notificationDialog = true;
		   },
		   // 显示编辑通知弹窗
		   showEditNotificationDialog(notification){
			   this.notificationDialogTitle = 'Edit Notification';
			   this.notificationForm = JSON.parse(JSON.stringify(notification));
			   this.notificationDialog = true;
		   },
		   // 显示删除通知弹窗
		   showDeleteDialog(id){
			   this.deleteNotificationId = id;
			   this.deleteDialog = true;
		   },
		   // 编辑通知
		   editNotification(){
			let api = config.baseUrl + '/notification/admin/private/edit';
			this.notificationLoading = true;
			this.$http.put(api, this.notificationForm).then(response => {
				let data = response.data;
				if (data.code == 200) {
					this.$store.dispatch('snackbarMessageHandler', data.message);
					this.notificationDialog = false;
					this.getNotifications(1);
					this.$refs.notificationForm.reset();
					this.notificationLoading = false;
				}
			}, error => {
				this.$store.dispatch('snackbarMessageHandler', data.message);
				this.notificationLoading = false;
			});
		   },
		   // 删除通知
		   deleteNotification(){
		   	let api = config.baseUrl + '/notification/admin/private/delete?id=' + this.deleteNotificationId;
			this.deleteDialog = false;
		   	this.$http.delete(api).then(response => {
		   	    let data = response.data;
		   	    if (data.code == 200) {
		   	        this.$store.dispatch('snackbarMessageHandler', data.message);
		   	        this.getNotifications(1);
		   	    }
		   	}, error => {
		   	    this.$store.dispatch('snackbarMessageHandler', data.message);
		   	});
		   }
        }
    }
</script>

<style>

</style>